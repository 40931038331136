<template>
  <q-dialog
    ref="dialogRef"
    :maximized="createPopUp.$state.maximized"
    :position="createPopUp.$state.Mobilposition"
  >
    <div
      class="mobil-popup"
      :style="{ top: positionTop + 'px' }"
      :class="{ 'return-to-top': positionTop === 0 }"
      v-touch-pan.mouse.down="handleSwipe"
    >
      <span class="mobil-popup-pull"></span>
      <component :is="createPopUp.$state.MobilcurrentPopUp" @hide_popup="closePopUp"></component>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
import { PopUpStore } from '~/store/popUp'
import { useDialogPluginComponent } from 'quasar'

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const createPopUp = PopUpStore()

const positionTop = ref<number>(0)

const closePopUp = (state: 'ok' | 'ca') => {
  if (state === 'ok') {
    onDialogOK()
  } else if (state === 'ca') {
    onDialogCancel()
  } else {
    onDialogHide()
  }
}

const handleSwipe = ({ evt, ...newInfo }) => {
  positionTop.value = newInfo.distance.y
  if (newInfo.isFinal) {
    if (positionTop.value >= 90) {
      dialogRef.value?.hide()
      createPopUp.$state.MobilcurrentPopUp = ''
      positionTop.value = 0
    } else {
      positionTop.value = 0
    }
  }
}
onMounted(() => {
  createPopUp.$state.popUpRef = dialogRef
})
</script>

<style lang="scss">
.mobil-popup {
  height: 100%;
  max-height: 100% !important;
  width: 100vw !important;
  max-width: 100vw !important;
  overflow: hidden;
  border-radius: 12px 12px 0px 0px !important;
  background: var(--white, #fff);
  padding: 16px;
  display: grid;
  justify-items: center;
  position: relative;
  &.return-to-top {
    transition: top 0.2s ease; // Adjust duration and easing as needed
  }

  &-pull {
    display: flex;
    width: 48px;
    height: 4px;
    flex-shrink: 0;
    border-radius: 100px;
    background: var(--slate-300);
  }
  .page__head-boxTitle {
    justify-self: flex-start;
    margin-bottom: 35px;
  }
  .butts-conteiner {
    display: grid;
    margin-top: 26px;
    gap: 8px;
    button {
      display: flex;
      width: 328px;
      padding: 13px 32px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 12px;
    }
    .end-deal {
      background: var(--slate-100);
      border: none;
    }
    .cancel {
      border: 1px solid var(--slate-200);
      background: var(--white, #fff);
    }
  }
}
.q-dialog__inner--minimized {
  padding: 0;
}
@media screen and (min-width: 200px) and (max-width: 767px) {
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .q-dialog {
    z-index: 9999999999;
    // max-width: calc(100dvw - 65px) !important;
  }
  
}
</style>
