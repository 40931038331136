import { defineStore } from 'pinia'
import PopUpComponent from '../components/PopUps/PopUp.vue'
import MobilPopUpComponent from '../components/Adaptives/Base/DownUpPopUp.vue'
import { Dialog } from 'quasar'
import QDialog from 'quasar'

type DialogPosition = 'standard' | 'top' | 'right' | 'bottom' | 'left' | undefined

export const PopUpStore = defineStore('PopUp', {
  state: () => ({
    popUpRef: undefined as typeof QDialog | undefined,
    createPopUp: false,
    phoneSuggestions: false,
    phoneSuggestionsBlur: false,
    objSearchSugg: false,
    awardInfoPopUp: false,
    anySugg: false,
    anySuggBlur: false,
    currentPopUp: '',
    currentPopUpData: null as any,
    PopUpPersistent: false,
    position: 'standart' as DialogPosition,
    maximized: undefined as boolean | undefined,
    MobilcreatePopUp: false,
    MobilcurrentPopUp: '',
    MobilcurrentPopUpData: null as any,
    MobilPopUpPersistent: false,
    Mobilposition: 'standart' as DialogPosition,
  }),
  actions: {
    callPopUp(args: {
      value: string
      data?: any
      persistent?: boolean
      position?: DialogPosition
      maximized?: boolean
    }) {
      this.maximized = args.maximized
      this.currentPopUp = args.value
      this.currentPopUpData = args.data
      this.createPopUp = true
      this.position = args.position
      this.position = args.position
      return new Promise<boolean>((resolve) => {
        Dialog.create({
          component: PopUpComponent,
          persistent: args.persistent,
          position: args.position,
          maximized: args.maximized,
          ok: {
            push: true,
          },
          cancel: {
            push: true,
          },
        })
          .onOk(() => {
            resolve(true)
          })
          .onCancel(() => {
            resolve(false)
          })
          .onDismiss(() => {
            resolve(false)
            this.maximized = false
          })
      })
    },
    callMobilPopUp(args:{value: string, data?: any, persistent?: boolean, position?: DialogPosition}) {
      return new Promise<boolean>((resolve) => {
        this.MobilcurrentPopUp = args.value
        this.MobilcurrentPopUpData = args.data
        this.MobilcreatePopUp = true
        this.Mobilposition = args.position
        Dialog.create({
          component: MobilPopUpComponent,
          persistent: args.persistent,
          position: args.position,
          maximized: this.maximized,
          ok: {
            push: true,
          },
          cancel: {
            push: true,
          },
        })
          .onOk(() => {
            resolve(true)
          })
          .onCancel(() => {
            resolve(false)
          })
          .onDismiss(() => {
            resolve(false)
          })
        
      })
    },
  },
})
